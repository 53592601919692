<template>
  <div class="distributions-wrapper is-relative">
    <Permission/>
    <Breadcrumb/>
    <b-button v-if="canCreate" type="is-blue" id="distribute-funds-btn" @click="toDistributeFundsPage">Distribute Funds</b-button>
    <div class="title">Distributions</div>
    <div class="subtitle">{{ corporationName }}</div>
    <Tabs/>
    <b-table :data="distributions" hoverable>
      <b-table-column
        v-for="column in columns"
        :key="column.key"
        :field="column.key"
        :label="column.name"
        v-slot="props"
      >
        <template v-if="isCurrencyColumn(column.key)">{{ props.row[column.key] | formatCurrency }}</template>
        <template v-else>{{props.row[column.key] || '-'}}</template>
      </b-table-column>
      <b-table-column field="csvDownload" label="CSV Review" v-slot="props">
        <b-button :loading="isDownloadingCsv" @click="onCsvDownload(props.row)">Download</b-button>
      </b-table-column>
      <template v-slot:empty>
        <div class="has-text-centered">
          <p class="py-5">No distributions found</p>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import corpDetailsMixin from '@utils/mixins/addy-plus-corporation-details-from-side-menu-mixin.js'
import { downloadFileFromUrl } from '@utils/common-methods/common'
import Permission from '@views/addy_plus/base/Permission.vue'
import Tabs from '../Tabs.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import { fetchDistributionList, fetchDistributionCsv } from '@api/addy-plus/wallet'
const COLUMNS = [
  {
    key: 'propertyName',
    name: 'Property Name',
  },
  {
    key: 'dateOfDistribution',
    name: 'Date of Distribution',
  },
  {
    key: 'totalAmount',
    name: 'Total Amount',
  },
  {
    key: 'businessIncome',
    name: 'Business Income',
  },
  {
    key: 'capitalGains',
    name: 'Capital Gains',
  },
  {
    key: 'rentalIncome',
    name: 'Rental Income',
  },
  {
    key: 'returnOfCapital',
    name: 'Return of Capital',
  },
]

export default {
  title: 'Due Diligence Uploads',
  mixins: [pageTitleMixin, corpDetailsMixin],
  components: {
    Permission,
    Tabs,
    Breadcrumb,
  },
  data() {
    return {
      columns: Object.freeze(COLUMNS),
      distributions: [],
      isDownloadingCsv: false,
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
    canCreate() {
      return this.permissions.entityDistributions?.includes('create')
    },
  },
  filters: {
    formatCurrency(value) {
      const number = Number(value)
      return number.toLocaleString('en-CA',
        { style: 'currency', currency: 'CAD' }
      )
    },
  },
  created() {
    this.fetchDistributions()
  },
  methods: {
    fetchDistributions() {
      fetchDistributionList(this.corporationId).then((response) => {
        if (!response.success) return
        this.distributions = response.data.distributions
      })
    },
    toDistributeFundsPage() {
      this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet/distributeFunds`)
    },
    onCsvDownload(rowData) {
      this.isDownloadingCsv = true
      const payload = this.parseDistributionPayload(rowData)
      fetchDistributionCsv(this.corporationId, payload).then((response) => {
        const urlCreator = window.URL || window.webkitURL
        const url = urlCreator.createObjectURL(response)
        downloadFileFromUrl(url, `distribution_${rowData.hashid}.csv`)
        this.isDownloadingCsv = false
      })
    },
    parseDistributionPayload(data) {
      const { rentalIncome, returnOfCapital, capitalGains, businessIncome, propertyHashid } = data
      return {
        property_id: propertyHashid,
        rental_income: Number(rentalIncome),
        return_of_capital: Number(returnOfCapital),
        capital_gains: Number(capitalGains),
        business_income: Number(businessIncome),
      }
    },
    isCurrencyColumn(columnKey) {
      return columnKey !== 'propertyName' && columnKey !== 'dateOfDistribution'
    },
  },
}
</script>
<style lang="scss" scoped>
.distributions-wrapper {
  font-size: 16px;
  #distribute-funds-btn {
    position: absolute;
    right: 12px;
    top: 20px;
  }
  .b-table ::v-deep {
    td {
      vertical-align: middle;
    }
  }
}
</style>
